<template>
  <div>
    <a-card title="采购询价">
      <a-row gutter="16">
        <a-col :span="24" style="width: 256px">
          <a-range-picker v-model="searchDate" allowClear @change="search" />
        </a-col>
        <a-col :md="8" :span="24" :xl="6" style="max-width: 200px; margin-bottom: 12px">
          <SupplierSelect v-model="searchForm.supplier" placeholder="供应商" @change="search" />
        </a-col>
        <a-col :span="24" style="width: 200px; margin-bottom: 12px">
          <a-select v-model="searchForm.status" placeholder="状态" allowClear style="width: 100%" @change="search">
            <a-select-option value="draft">草稿</a-select-option>
            <a-select-option value="committed">已提交</a-select-option>
          </a-select>
        </a-col>
        <a-col :md="8" :span="24" :xl="6" style="max-width: 200px; margin-bottom: 12px">
          <a-input-search v-model="searchForm.search" allowClear placeholder="采购询价单号" @search="search" />
        </a-col>
        <a-col :span="24" style="width: 100px; margin-bottom: 12px">
          <a-button type="primary" icon="search" @click="search">查询</a-button>
        </a-col>

        <div style="margin-bottom: 12px; float: right">
          <a-button icon="plus" style="margin: 0 8px" type="primary" @click="addData">新增采购询价</a-button>
        </div>
      </a-row>

      <a-row style="margin-top: 12px">
        <a-table
          :columns="columns"
          :dataSource="items"
          :loading="loading"
          :pagination="pagination"
          rowKey="id"
          size="small"
          @change="tableChange"
        >
          <div slot="number" slot-scope="value, item">
            <a @click="detail(item)">{{ value }}</a>
          </div>
          <div slot="action" slot-scope="value, item">
            <a-button-group size="small">
              <a-button size="small" :disabled="item.status === 'committed'" @click="edit(item)"> 编辑 </a-button>
              <a-popconfirm title="确定提交吗?" :disabled="item.status === 'committed'" @confirm="commit(item)">
                <a-button type="primary" size="small" :disabled="item.status === 'committed'">提交</a-button>
              </a-popconfirm>
              <a-popconfirm title="确定删除吗?" @confirm="destroy(item)">
                <a-button type="danger">删除</a-button>
              </a-popconfirm>
            </a-button-group>
          </div>
        </a-table>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import { purchaseInquiryOrderList, purchaseInquiryOrderDestroy, purchaseInquiryOrderCommit } from "@/api/purchasing";

export default {
  components: {
    SupplierSelect: () => import("@/components/SupplierSelect"),
  },
  data() {
    return {
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          customRender: (value, item, index) => index + 1,
        },
        {
          title: "采购询价单号",
          dataIndex: "number",
          scopedSlots: { customRender: "number" },
        },
        {
          title: "供应商",
          dataIndex: "supplier_name",
          customRender: (value, item) => item.supplier_items.map((supplierItem) => supplierItem.name).join(";"),
        },
        {
          title: "状态",
          dataIndex: "status_display",
        },
        {
          title: "截止时间",
          dataIndex: "closing_time",
        },
        {
          title: "备注",
          dataIndex: "remark",
        },
        {
          title: "创建人",
          dataIndex: "creator_name",
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
        },
        {
          title: "操作",
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
          fixed: "right",
        },
      ],
      searchForm: { page: 1, page_size: 10 },
      pagination: { current: 1, total: 0, pageSize: 10 },
      loading: false,
      items: [],
      visible: false,

      searchDate: [undefined, undefined],
    };
  },
  computed: {},
  methods: {
    initData() {
      this.list();
    },
    list() {
      const searchForm = {
        ...this.searchForm,
        start_date: this.searchDate[0] ? this.searchDate[0].clone().format("YYYY-MM-DD") : undefined,
        end_date: this.searchDate[1] ? this.searchDate[1].clone().add(1, "days").format("YYYY-MM-DD") : undefined,
      };

      this.loading = true;
      purchaseInquiryOrderList(searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    tableChange(pagination, filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order === "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    onChangePicker(date, dateString) {
      let startDate = date[0],
        endDate = date[1];
      this.searchForm.start_date = startDate ? startDate.format("YYYY-MM-DD") : undefined;
      this.searchForm.end_date = endDate ? endDate.add(1, "days").format("YYYY-MM-DD") : undefined;
      this.search();
    },
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    destroy(item) {
      purchaseInquiryOrderDestroy({ id: item.id }).then(() => {
        this.$message.success("删除成功");
        this.list();
      });
    },
    addData() {
      this.$router.push({ path: "/purchasing/purchase_inquiry_order_create" });
    },
    detail(item) {
      this.$router.push({ path: "/purchasing/purchase_inquiry_order_detail", query: { id: item.id } });
    },
    edit(item) {
      this.$router.push({ path: "/purchasing/purchase_inquiry_order_update", query: { id: item.id } });
    },
    commit(item) {
      purchaseInquiryOrderCommit({ id: item.id }).then(() => {
        this.$message.success("提交成功");
        this.list();
      });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
